import React from 'react';
import {
  AiOutlineCheckCircle,
  AiOutlineDashboard,
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineUser,
} from 'react-icons/ai';
import { Group, Row, SubRow } from './styles';

interface IMenuItem {
  id: string;
  name: string;
  isSelected?: boolean;
  isSubMenu?: boolean;
  isCollapsed?: boolean;
  onCollapse?: (id: string) => void;
  onNavigate?: (id: string) => void;
}

export const MenuItem: React.FC<IMenuItem> = ({
  id,
  name,
  isSelected = false,
  isSubMenu = false,
  isCollapsed,
  onCollapse,
  onNavigate,
}) => {
  const chevronColor = 'rgba(0, 0, 0, 0.85)';
  const menuIcons: MenuIconTypes = {
    dashboard: <AiOutlineDashboard color={chevronColor} />,
    insights: <AiOutlineCheckCircle color={chevronColor} />,
    account: <AiOutlineUser color={chevronColor} />,
  };

  const onClickFunction = () => {
    if (isSubMenu && onNavigate) {
      onNavigate(id);
    } else if (onCollapse) {
      onCollapse(id);
    }
  };
  return !isSubMenu ? (
    <Row isSelected={isSelected} onClick={onClickFunction}>
      <Group>
        {' '}
        {menuIcons[id]}
        <span>{name}</span>
      </Group>
      {isCollapsed ? (
        <AiOutlineUp color={chevronColor} />
      ) : (
        <AiOutlineDown color={chevronColor} />
      )}
    </Row>
  ) : (
    <SubRow isSelected={isSelected} onClick={onClickFunction}>
      <span>{name}</span>
    </SubRow>
  );
};

import { styled } from 'src/modules';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding-inline: 5px;
  span {
    color: ${({ theme }) => theme.colors.character.primary85};
    font-size: 14px;
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.blue.primary1};
  border-radius: 100px;
  overflow: hidden;
`;

type BarIndicatorProps = {
  percentage: number;
};
export const BarIndicator = styled.div<BarIndicatorProps>`
  background-color: ${({ theme }) => theme.colors.blue.primary6};
  height: inherit;
  width: ${({ percentage }) => `${percentage}%`};
`;

import * as AntD from 'antd';
import { lighten, transparentize } from 'polished';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import * as Toastify from 'react-toastify';
import styled, { css, useTheme } from 'styled-components';
import * as FormValidator from 'yup';

import * as CNPJ from '@fnando/cnpj';
import * as CPF from '@fnando/cpf';

export {
  Toastify,
  FormValidator,
  CPF,
  CNPJ,
  styled,
  css,
  transparentize,
  lighten,
  useHistory,
  useTheme,
  useRouteMatch,
  Link,
  AntD,
};
export { Formik as FormBehavior } from 'formik';
export { default as DateManager } from 'moment';
export { default as Numeral } from 'numeral';
export { default as TextInputMask } from 'react-input-mask';
export { default as Animation } from 'react-lottie';
export { observer, inject, MobXProviderContext, Provider } from 'mobx-react';
export { persist } from 'mobx-persist';
export { action, makeObservable, observable } from 'mobx';

export { useForm } from 'react-hook-form';

export const pxToRem = (pixels: number, baseline = 16): string => {
  return `${pixels / baseline}rem`;
};

import React, { Component } from 'react';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

export type AreaChartProps = {
  xAxis: string[] | number[];
  values: ApexOptions['series'];
};
class AreaChart extends Component<
  { xAxis: string[] | number[]; values: ApexOptions['series'] },
  AreaChartProps
> {
  constructor(props: AreaChartProps) {
    super(props);
    this.state = {
      xAxis: props.xAxis,
      values: props.values,
    };
  }

  render() {
    const { xAxis, values } = this.state;
    const options: ApexOptions = {
      chart: {
        id: 'basic-bar',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: xAxis,
      },
    };

    return (
      <div className="chart_app">
        <div className="row">
          <div className="mixed-chart">
            <Chart options={options} series={values} type="area" width="350" />
          </div>
        </div>
      </div>
    );
  }
}

export default AreaChart;

import { css, styled } from 'src/modules';

interface MenuProps {
  isSelected: boolean;
}
export const Row = styled.div<MenuProps>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-inline: 16px;
  padding-block: 9px;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  span {
    display: block;
    margin-left: 10px;
    color: ${({ isSelected }) =>
      isSelected ? '#1890FF' : 'rgba(0, 0, 0, 0.85)'};
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const SubRow = styled.div<MenuProps>`
  cursor: pointer;

  padding-block: 9px;
  padding-left: 40px;
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: #e6f7ff;
          border-right: 3px solid #1890ff;
        `
      : ''}
  span {
    color: ${({ isSelected }) =>
      isSelected ? '#1890FF' : 'rgba(0, 0, 0, 0.85)'};
  }
`;

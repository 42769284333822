import { css, styled } from 'src/modules';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h3``;

export const EventsWrapper = styled.div`
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral4};
  border-radius: 2px;
`;

export const EventBox = styled.div`
  /* background-color: grey; */
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-height: 64px;
  font-size: 14px;
  font-weight: 500;
  span {
    color: ${({ theme }) => theme.colors.character.secondary45};
  }
  p {
    color: ${({ theme }) => theme.colors.character.primary85};
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;
`;
export const MarkPoint = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.neutral.neutral1};
  border: 2px solid ${({ theme }) => theme.colors.blue.primary6};
  border-radius: 100px;
`;
type LineProps = {
  isOnTop?: boolean;
  isFirst?: boolean;
};
export const Line = styled.div<LineProps>`
  width: 1px;
  ${({ isOnTop }) =>
    isOnTop
      ? css`
          height: 6px;
        `
      : css`
          height: 100%;
        `}
  ${({ isFirst }) =>
    isFirst
      ? css`
          background: transparent;
        `
      : css`
          background-color: ${({ theme }) => theme.colors.divider};
        `}
`;
export const Right = styled.div``;

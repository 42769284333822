import { ButtonProps } from './types';
import { css, styled } from 'src/modules';

const variants = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.blue.primary6};
    color: white;
    :hover {
      background-color: ${({ theme }) => theme.colors.blue.primary5};
    }
  `,
  secondary: css`
    background: transparent;
    color: ${({ theme }) => theme.colors.blue.primary6};
    :hover {
      background: ${({ theme }) => theme.colors.primaryDark};
      color: ${({ theme }) => theme.colors.white};
    }
  `,
  transparent: css`
    background: transparent;
  `,
};

export const Container = styled.button<ButtonProps>`
  ${({ theme, variant }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 2.4rem;
    width: 100%;
    border-radius: 2px;
    transition: ${theme.transition.default};

    * {
      transition: ${theme.transition.default};
    }

    ${variant && variants[variant]};

    :disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  `}
`;

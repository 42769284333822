import { styled } from 'src/modules';

export const Box = styled.div`
  width: 325px;
  height: auto;
  background-color: ${({ theme }) => theme.colors.neutral.neutral1};
`;

export const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 16px 16px;
  box-shadow: inset 0px -1px 0px #f0f0f0;

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.character.primary85};
    display: inline-block;
  }
  a {
    margin: 0;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    vertical-align: center;
  }
`;

export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 25px;
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.character.secondary45};
  }
`;

export const Strong = styled.strong`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.character.primary85};
`;

import { styled } from 'src/modules';

export const Box = styled.div`
  width: 254px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.neutral.neutral1};
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral4};
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  span {
    color: ${({ theme }) => theme.colors.character.secondary45};
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Row = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

import React from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { parseToThousands } from '@gaialabsteam/edypo';
import { useTheme } from 'src/modules';

type Props = {
  value: number;
};

const ComparativePercentage: React.FC<Props> = ({ value = 0 }) => {
  const { colors } = useTheme();
  const carets = {
    positive: <AiFillCaretUp color={colors.success} />,
    negative: <AiFillCaretDown color={colors.red.dustRed7} />,
  };
  const type = value > 0 ? 'positive' : 'negative';
  let formattedValue = value ? value.toString() : '0';
  if (value < 0) {
    formattedValue = value.toString().substring(0, 5);
  }
  return (
    <span>
      {type === 'positive' ? '+' : ''}
      {parseToThousands(formattedValue)}% {carets[type]}
    </span>
  );
};

export default ComparativePercentage;

import React from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

export type PercentageChartType = {
  size: number;
  values: number[];
  labels: string[];
};
export default class PercentageChart extends React.Component<
  { size: number; values: number[]; labels: string[] },
  PercentageChartType
> {
  constructor(props: PercentageChartType) {
    super(props);
    this.state = {
      size: props.size,
      values: props.values,
      labels: props.labels,
    };
  }

  render() {
    const { size, values, labels } = this.state;
    const options: ApexOptions = {
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          },
        },
      },
      labels,
    };
    return (
      <ReactApexChart
        options={options}
        series={values}
        type="radialBar"
        height={size}
        width={size}
      />
    );
  }
}

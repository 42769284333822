import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './private';
import Public from './public';
import { Routes } from './routing';
import { HomeScene, LoginScene } from 'src/screens';
import Layout from 'src/screens/_layouts/default';
import { Storage } from 'src/services';

const RoutesContainer: React.FC = () => {
  const [token, setToken] = useState(Storage.getToken());
  if (!token) {
    return <LoginScene onSetToken={setToken} />;
  }
  return (
    <Switch>
      {token ? (
        <Layout>
          <PrivateRoute exact path={`${Routes.HOME}`} component={HomeScene} />
          <Public exact path={Routes.LOGIN} component={LoginScene} />
        </Layout>
      ) : (
        <Route exact path={`${Routes.LOGIN}/`} component={LoginScene} />
      )}
    </Switch>
  );
};

export { Routes };
export default inject('routing')(observer(RoutesContainer));

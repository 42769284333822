import { styled } from 'src/modules';

// @TODO: Pegar cores do tema
export const Wrapper = styled.div`
  height: 28px;
  padding: 4px 8px;
  border-radius: 32px;
  background: #f1f3f5;
  color: #495057;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  span + span {
    margin-left: 12px;
  }
`;

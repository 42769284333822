import React, { memo } from 'react';

import { AiOutlineLogout } from 'react-icons/ai';
import { Container } from './styles';
import { DiweLogo } from 'src/assets/images';
import { useStores } from 'src/hooks';
import { useHistory } from 'src/modules';
import { Routes } from 'src/routes';
import { Storage } from 'src/services';

const Header: React.FC = () => {
  const history = useHistory();
  const { auth } = useStores();
  const onBack = () => history.goBack();
  const onLogout = async () => {
    await Storage.clearWholeStorage();
    history.replace(Routes.LOGIN);
  };
  return (
    <Container>
      <img src={DiweLogo} alt="Logo Diwe" />
      <AiOutlineLogout
        color="white"
        size={30}
        cursor="pointer"
        onClick={onLogout}
      />
    </Container>
  );
};

export default memo(Header);

import request from './request';
import { ResponseError } from 'src/utils';

export const fetchBudgetById = async (id: string): Promise<BudgetTypes> => {
  try {
    const { data } = await request.get(`/budgets/${id}`);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const fetchBudget = async (
  accessToken: string,
  page = 1,
): Promise<BudgetDetailsListTypes> => {
  try {
    const { data } = await request.get(
      `/budgets/details/${accessToken}?page=${page}`,
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

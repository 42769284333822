import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Form, Image, Left, Right, Wrapper } from './styles';
import { DiweLogoAlpha, DiweLogoGrey } from 'src/assets/images';
import { Button, Loader } from 'src/components';
import InfoBox from 'src/components/Common/InfoBox';
import Input from 'src/components/Common/Input';
import { observer, useHistory } from 'src/modules';
import { Routes } from 'src/routes';
import store from 'src/stores';

type LoginProps = {
  onSetToken(token: Token): void;
};
const Login: React.FC<LoginProps> = ({ onSetToken }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const { auth } = store;
  const history = useHistory();
  const onSubmitForm = async (event: React.FormEvent) => {
    setIsLoading(true);
    setIsError(false);
    event.preventDefault();
    try {
      const authToken = await auth.login({ email, password });
      history.push(Routes.HOME);
      onSetToken(authToken);
    } catch (err) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Wrapper>
      <Left />
      <Right>
        <Image src={DiweLogoAlpha} alt="Logo DIWE" />
        <Form onSubmit={onSubmitForm}>
          <Input value={email} handleChange={setEmail} placeholder="usuário" />
          <Input
            type="password"
            value={password}
            handleChange={setPassword}
            placeholder="senha"
          />
          <Button disabled={isLoading} type="submit" variant="primary">
            Entrar
          </Button>
          {isLoading && <Loader />}
          {isError && (
            <>
              <InfoBox
                type="error"
                label1="Usuário ou senha incorretos"
                label2="Verifique se os seus dados estão corretos"
              />
              <InfoBox
                type="warning"
                label1="Entre em contato com o time de suporte"
              />
            </>
          )}
        </Form>
        <Image src={DiweLogoGrey} alt="Logo DIWE" />
      </Right>
    </Wrapper>
  );
};

export default observer(Login);

import { styled } from 'src/modules';

const characterSecondary = 'rgba(0, 0, 0, 0.45);';
const characterTitle85 = 'rgba(0, 0, 0, 0.85)';
export const Box = styled.div`
  width: 580px;
  padding: 24px;
  background-color: white;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Heading = styled(Row).attrs({})`
  padding-bottom: 12px;
  box-shadow: 0px -1px 0px 0px rgba(240, 240, 240, 1) inset;
  margin-bottom: 24px;
  h3 {
    color: ${({ theme }) => theme.colors.character.primary85};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MonthLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${characterSecondary};
`;

export const NumberIndicator = styled.strong`
  margin-top: 9px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${characterTitle85};
  span {
    font-size: 24px;
  }
`;

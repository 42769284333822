import { styled } from 'src/modules';

export const Box = styled.div`
  width: 260px;
  height: 243px;
  background-color: ${({ theme }) => theme.colors.neutral.neutral1};
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  overflow-y: hidden;
`;

export const Top = styled.img`
  width: 100%;
  max-height: 140px;
`;

export const Bottom = styled.div`
  padding: 16px;
`;
export const Header = styled.h4`
  color: ${({ theme }) => theme.colors.character.primary85};
  font-size: 16px;
  font-weight: 400;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.character.secondary45};
  font-weight: 400;
  font-size: 14px;
`;

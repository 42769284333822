import React from 'react';
import {
  EventBox,
  EventsWrapper,
  Left,
  Line,
  MarkPoint,
  Right,
  Title,
  Wrapper,
} from './styles';
import { TimeLineProps } from './types';
import { formatToTimeLineDate } from 'src/utils';

const TimeLine: React.FC<TimeLineProps> = ({ title, events }) => {
  return (
    <Wrapper>
      <Title> {title}</Title>
      <EventsWrapper>
        {events.map((event, index) => {
          const isFirst = index === 0;
          return (
            <EventBox>
              <Left>
                <Line isOnTop isFirst={isFirst} />
                <MarkPoint />
                <Line />
              </Left>
              <Right>
                <span>em {formatToTimeLineDate(event.date)}</span>
                <p>{event.description}</p>
              </Right>
            </EventBox>
          );
        })}
      </EventsWrapper>
    </Wrapper>
  );
};

export default TimeLine;

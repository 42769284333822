import React, { useCallback, useEffect, useState } from 'react';
import Home from './Home';
import { useStores } from 'src/hooks';
import { observer, useHistory, useRouteMatch } from 'src/modules';

const HomeContainer: React.FC = () => {
  return <Home />;
};

export default observer(HomeContainer);

import { styled } from 'src/modules';

export const Main = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: row;
  min-height: 100vh;
  gap: 5rem;
`;

export const Column = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 20px;
`;
export const Aside = styled.aside`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  h3 {
    color: ${({ theme }) => theme.colors.character.primary85};
  }
`;

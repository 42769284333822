import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { variant } from 'styled-system';

import { Container } from './styles';
import { ButtonProps } from './types';

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  variant,
  ...props
}) => {
  const { children, type, ...rest } = props;

  return (
    <Container type={type || 'button'} variant={variant} {...rest}>
      {children}
    </Container>
  );
};

export default Button;

import React from 'react';
import { currencyParser, parseToThousands } from '@gaialabsteam/edypo';
import { Number } from './styles';

type Props = {
  value: number;
  type: 'currency' | 'percentage' | 'number';
  color?: string;
};
const Numeral: React.FC<Props> = ({ value, type, color }) => {
  const getValue = (valueToFormat: string): string => {
    try {
      if (type === 'currency') {
        return currencyParser(valueToFormat, 2);
      }
      if (type === 'percentage') {
        const formattedPercentage = parseToThousands(value.toString());
        return `${formattedPercentage}%`;
      }

      return parseToThousands(valueToFormat);
    } catch (err) {
      return '';
    }
  };
  return (
    <>
      <Number color={color}>{getValue(value.toString())}</Number>
    </>
  );
};

export default Numeral;

import { css, styled } from 'src/modules';

const boxStyles = {
  error: css`
    background: ${({ theme }) => theme.colors.red.dustRed1};
    border: 1px solid ${({ theme }) => theme.colors.red.dustRed2};
  `,
  warning: css`
    background: ${({ theme }) => theme.colors.gold.calendulaGold1};
    border: 1px solid ${({ theme }) => theme.colors.gold.calendulaGold3};
  `,
};

type BoxProps = {
  type: 'error' | 'warning';
};

export const Column = styled.div``;
export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.character.primary85};
  padding: 0.5rem 1rem;
  gap: 5px;
  font-size: 1.5rem;
  ${({ type }) => boxStyles[type]};
`;

export const Row = styled.div`
  flex-direction: row;
  justify-content: space-around;
`;

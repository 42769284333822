import { action, computed, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { AuthApi } from 'src/api';
import { userInitialValues } from 'src/constants';
import { Storage } from 'src/services';

export default class AuthStore {
  @persist('object')
  @observable
  userData: User = userInitialValues;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getUserName(): string {
    return this.userData.full_name;
  }

  @action
  login = async ({ email, password }: Credentials): Promise<Token> => {
    const credentials = { email, password };
    const token = await AuthApi.login(credentials);
    Storage.setToken(token);
    return token;
  };

  @action
  logout = async (): Promise<any> => {
    this.userData = userInitialValues;
    await Storage.clearWholeStorage();
  };
}

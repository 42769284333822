import React from 'react';
import If from '../If';
import {
  ButtonAccept,
  ButtonDecline,
  ButtonDeclineDisabled,
  ButtonWhatsapp,
  ButtonsWrapper,
  FooterWrapper,
  Wrapper,
} from './styles';
import { observer } from 'src/modules';
import {
  ApplierResponseStatus,
  ModalOptions,
  formatToScreenDate,
} from 'src/utils';

type Props = {
  handleOpenModal(modal: ModalTypes): void;
  invitation: ApplierInvitation;
  whatsappLink: string;
  onContactUser(): void;
  onAcceptInvite(): void;
};

const SectionRequestActions: React.FC<Props> = ({
  handleOpenModal,
  invitation,
  whatsappLink,
  onContactUser,
  onAcceptInvite,
}) => {
  const { applier_response, applier_responded_at } = invitation.budgetApplier;

  return (
    <>
      <If condition={applier_response === ApplierResponseStatus.PENDING}>
        <FooterWrapper>
          <Wrapper>
            <p>Deseja aceitar essa solicitação?</p>
            <ButtonsWrapper>
              <ButtonAccept onClick={onAcceptInvite}>Aceitar</ButtonAccept>
              <ButtonDecline
                onClick={() => handleOpenModal(ModalOptions.DECLINE_FIRST)}
              >
                Recusar
              </ButtonDecline>
            </ButtonsWrapper>
          </Wrapper>
        </FooterWrapper>
      </If>

      <If condition={applier_response === ApplierResponseStatus.REFUSED}>
        <FooterWrapper>
          <Wrapper>
            <p>
              Você <strong>recusou</strong> essa solicitação
            </p>
            <ButtonDeclineDisabled>
              Recusou em {formatToScreenDate(applier_responded_at)}
            </ButtonDeclineDisabled>
          </Wrapper>
        </FooterWrapper>
      </If>

      <If condition={applier_response === ApplierResponseStatus.ACCEPTED}>
        <FooterWrapper>
          <Wrapper>
            <p>
              Você <strong>aceitou</strong> essa solicitação
            </p>
            <ButtonWhatsapp
              href={whatsappLink}
              target="_blank"
              onClick={onContactUser}
            >
              Chamar no Whatsapp
            </ButtonWhatsapp>
          </Wrapper>
        </FooterWrapper>
      </If>
    </>
  );
};

export default observer(SectionRequestActions);

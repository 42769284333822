import { styled } from 'src/modules';

export const Box = styled.div`
  width: 254px;
  padding-inline: 24px;
  padding-block: 24px 8px;
  background-color: ${({ theme }) => theme.colors.neutral.neutral1};
  border: 1px solid ${({ theme }) => theme.colors.divider};
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.character.secondary45};
  }
  strong {
    font-size: 30px;
  }
`;

export const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 5px;
  width: 100%;
`;

export const ChildrenRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40px;
  max-height: 90px;
`;
export const BottomRow = styled.div`
  width: 100%;
  padding-top: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
`;

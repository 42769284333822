import { styled } from 'src/modules';

export const Box = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.neutral1};
  color: ${({ theme }) => theme.colors.character.primary85};

  h3 {
    font-size: 24px;
    font-weight: 400;
  }
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    text-align: left;
    width: 85%;
    margin-block: 40px;
  }
  strong {
    font-weight: 500;
    font-size: 16px;
    span {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.character.secondary45};
    }
  }
`;

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HighlightsList = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
`;

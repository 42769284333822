import styled from 'styled-components';
import { pxToRem } from 'src/modules';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ChildrenWrapper = styled.div`
  width: ${pxToRem(1020)};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -${pxToRem(100)};
`;

export const Constrain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

import React, { PropsWithChildren } from 'react';
import { AiFillCheckCircle, AiFillInfoCircle } from 'react-icons/ai';
import { Card, IconWrapper } from './styles';
import { CTAComponentProps } from './types';

const CTA: React.FC<PropsWithChildren<CTAComponentProps>> = ({
  variant = 'success',
  text,
  children,
}) => {
  const iconsSize = 20;
  const icons = {
    success: <AiFillCheckCircle size={iconsSize} color="#52C41A" />,
    info: <AiFillInfoCircle size={iconsSize} color="#1890FF" />,
  };
  return (
    <Card variant={variant}>
      <IconWrapper>{icons[variant]}</IconWrapper>
      {children || text}
    </Card>
  );
};
export default CTA;

import React, { useMemo } from 'react';
import { Select } from 'antd';
import { subtractDaysFromToday } from 'src/utils/helpers';

const { Option } = Select;
type DatePickerProps = {
  onChange(value: DatePickerValue): void;
  keyValue: DatePickerKeys;
};

export type DatePickerValue = {
  key: DatePickerKeys;
  value: DateIntervalType;
};

export type DatePickerKeys =
  | 'today'
  | 'days7'
  | 'days14'
  | 'days28'
  | 'days90'
  | 'days180'
  | 'lastQuarter'
  | 'currentQuarter'
  | 'lastYear'
  | 'currentYear';
const DatePicker: React.FC<DatePickerProps> = ({ onChange, keyValue }) => {
  const dateObjects = useMemo(() => {
    return {
      today: { start_date: new Date(), end_date: new Date() },
      days7: subtractDaysFromToday(7),
      days14: subtractDaysFromToday(14),
      days28: subtractDaysFromToday(28),
      days90: subtractDaysFromToday(90),
      days180: subtractDaysFromToday(180),
      lastQuarter: subtractDaysFromToday(7),
      currentQuarter: subtractDaysFromToday(7),
      lastYear: subtractDaysFromToday(7),
      currentYear: subtractDaysFromToday(7),
    };
  }, []);
  const handleChange = (key: DatePickerKeys) => {
    const dateInterval = dateObjects[key];
    onChange({ key, value: dateInterval });
  };

  const options = [
    { label: 'Hoje', value: 'today' },
    { label: 'Últimos 7 dias', value: 'days7' },
    { label: 'Últimos 14 dias', value: 'days14' },
    { label: 'Últimos 28 dias', value: 'days28' },
    { label: 'Últimos 90 dias', value: 'days90' },
    { label: 'Últimos 180 dias', value: 'days180' },
    // { label: 'Último Quarter', value: 'lastQuarter' },
    // { label: 'Este Quarter', value: 'currentQuarter' },
    // { label: 'Ano passado', value: 'lastYear' },
    // { label: 'Este ano', value: 'currentYear' },
  ];
  return (
    <Select
      value={keyValue}
      style={{ width: 200 }}
      onChange={(value: DatePickerKeys) => handleChange(value)}
    >
      {options.map((option) => (
        <Option value={option.value}>{option.label}</Option>
      ))}
    </Select>
  );
};

export default DatePicker;

import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { ApplierApi } from 'src/api';
import { applierInitialValues, budgetInitialValues } from 'src/constants';
import { ApplierResponseStatus } from 'src/utils';

export default class ApplierStore {
  @persist('object')
  @observable
  current: AppliersTypes = {
    id: 0,
    full_name: '',
    phone: '',
    email: '',
    id_pdc: '',
    city: '',
    state: '',
    profession: '',
    description: '',
    status: '',
    created_at: '',
    updated_at: '',
    photo_url: '',
  };

  @observable
  invitation: ApplierInvitation = {
    budgetApplier: {
      id: 0,
      link_clicked: false,
      whatsapp_clicked: false,
      budget_id: 0,
      applier_id: 0,
      created_at: '',
      updated_at: '',
      applier_contacted_from_details: false,
      applier_contacted_at: '',
      user_contacted_by_applier: false,
      user_contacted_at: '',
      applier_response: ApplierResponseStatus.PENDING,
      applier_responded_at: '',
      applier: applierInitialValues,
      budget: budgetInitialValues,
    },
    user: {
      id: 0,
      full_name: '',
      phone: '',
      email: '',
      status: '',
      role_id: 0,
      remember_me_token: '',
      created_at: '',
      updated_at: '',
    },
  };

  constructor() {
    makeObservable(this);
  }

  @action
  fetchById = async (id: string): Promise<AppliersTypes> => {
    const applier = await ApplierApi.getById(id);

    this.current = applier;
    return applier;
  };

  @action
  fetchByPdcId = async (id: string): Promise<AppliersTypes> => {
    const applier = await ApplierApi.getByPdcId(id);

    this.current = applier;
    return applier;
  };

  @action
  fetchInvitation = async (
    accessToken: string,
    id: string,
  ): Promise<ApplierInvitation> => {
    const invitation = await ApplierApi.fetchInvitation(accessToken, id);

    this.invitation = invitation;
    return invitation;
  };
}

import request from './request';
import { ResponseError, formatToAPIDate } from 'src/utils';

export const getByPeriod = async (
  start_date: Date,
  end_date: Date,
): Promise<WeeklyAnalysysType> => {
  try {
    const { data } = await request.get('/weekly-analysis/updated-insights', {
      params: {
        start_date: formatToAPIDate(start_date.toString()),
        end_date: formatToAPIDate(end_date.toString()),
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getMilestones = async (): Promise<MilestoneType[]> => {
  try {
    const { data } = await request.get('/milestone');
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};
export const getOpportunities = async (): Promise<OpportunityType[]> => {
  try {
    const { data } = await request.get('/opportunity');
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getComingNext = async (): Promise<ComingNextType[]> => {
  try {
    const { data } = await request.get('/comming-next');
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

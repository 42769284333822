import React, { useEffect, useState } from 'react';
import DatePicker, { DatePickerKeys, DatePickerValue } from '../DatePicker';
import Loader from '../Loader';
import { Box, Column, Heading, NumberIndicator, Row } from './styles';
import { NorthStarProps } from './types';
import { AreaChart, PercentageChart } from 'src/components/Charts';
import { northStarInitialValue } from 'src/constants';
import { useStores } from 'src/hooks';

const NorthStarMetric: React.FC<NorthStarProps> = ({ title }) => {
  const [northStarData, setNorthStarData] = useState<NorthStarType>(
    northStarInitialValue,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [datePickerKey, setDatePickerKey] = useState<DatePickerKeys>('days7');
  const { northStar } = useStores();
  const end_date = new Date();
  const start_date = new Date();
  start_date.setDate(end_date.getDate() - 7);

  const fetchData = async (dateInterval: DateIntervalType) => {
    setIsLoading(true);
    try {
      const data = await northStar.getNorthStar(
        dateInterval.start_date,
        dateInterval.end_date,
      );
      console.log('NORTH STAR', data);
      setNorthStarData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeDate = (dateValue: DatePickerValue) => {
    setDatePickerKey(dateValue.key);
    fetchData(dateValue.value);
  };
  useEffect(() => {
    fetchData({ start_date, end_date });
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Heading>
            <h3>{title}</h3>
            <DatePicker keyValue={datePickerKey} onChange={onChangeDate} />
          </Heading>
          <Row
            style={{ marginBottom: 24, justifyContent: 'flex-start', gap: 50 }}
          >
            <Column>
              <span>Esperado</span>
              <NumberIndicator>
                <span>{northStarData.realConversions}</span> Novos usuários
              </NumberIndicator>
            </Column>
            <Column>
              <span>Realizado</span>
              <NumberIndicator>
                <span>{northStarData.expectedConversions} </span>Novos usuários
              </NumberIndicator>
            </Column>
          </Row>
          <Row>
            <PercentageChart
              size={200}
              labels={['Novos']}
              values={[northStarData.metricPercentage]}
            />
            <AreaChart
              values={[
                { name: 'Esperado', data: [100, 200, 300] },
                { name: 'Realizado', data: [100, 50, 100] },
              ]}
              xAxis={['jan 1', 'fev 1', 'mar 1']}
            />
          </Row>
        </>
      )}
    </Box>
  );
};

export default NorthStarMetric;

import React, { useEffect, useState } from 'react';

import { Select, Space } from 'antd';

import { Aside, Column, Main } from './styles';
import { WeeklyAnalysysApi } from 'src/api';

import {
  Button,
  CTA,
  HighlightComponent,
  KPI,
  KR,
  NorthStarMetric,
  TimeLine,
  WeeklyAnalysys,
} from 'src/components';
import { CTAVariantType } from 'src/components/CTA/types';
import { NorthStarDataType } from 'src/components/NorthStarMetric/types';
import { AntD } from 'src/modules';

const handleChange = (value: string) => {};
const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timelineData, setTimelineData] = useState<any[]>([]);
  const [opportunities, setOpportunities] = useState<OpportunityType[]>([]);
  const [ComingNext, setComingNext] = useState<ComingNextType[]>([]);

  const fetchMilestones = async () => {
    const data = await WeeklyAnalysysApi.getMilestones();
    const timeline = data.map((item) => {
      return {
        date: item.referenceDate,
        description: item.content,
      };
    });
    setTimelineData(timeline);
  };
  const fetchOpportunities = async () => {
    const data = await WeeklyAnalysysApi.getOpportunities();
    setOpportunities(data);
  };
  const fetchComingNext = async () => {
    const data = await WeeklyAnalysysApi.getComingNext();
    setComingNext(data);
  };

  const fetchAsideData = async () => {
    setIsLoading(true);
    try {
      await fetchMilestones();
      await fetchOpportunities();
      await fetchComingNext();
    } catch (error) {
      console.error;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAsideData();
  }, []);
  return (
    <Main>
      <Column>
        <WeeklyAnalysys
          data={{
            title: 'Visibilidade dos impactos do marketing',
            date: new Date(),
            lastUpdated: new Date(),
          }}
        />
        <NorthStarMetric title="North Star Metric" />
      </Column>
      <Aside>
        <TimeLine title="Marcos da semana" events={timelineData} />
        <h3 style={{ marginTop: 10 }}>Oportunidades</h3>
        {opportunities.map((op) => (
          <>
            <CTA
              variant={op.type.toLowerCase() as CTAVariantType}
              text={op.content}
            />
          </>
        ))}
      </Aside>
    </Main>
  );
};

export default Home;

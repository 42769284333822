import { CTAVariantType } from './types';
import { css, styled } from 'src/modules';

const cardVariants = {
  success: css`
    border: 1px solid ${({ theme }) => theme.colors.green.polarGreen3};
    background-color: ${({ theme }) => theme.colors.green.polarGreen1};
  `,
  info: css`
    border: 1px solid ${({ theme }) => theme.colors.blue.primary3};
    background-color: ${({ theme }) => theme.colors.blue.primary1};
  `,
};
interface CardProps {
  variant: CTAVariantType;
}
export const Card = styled.div<CardProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 2px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  ${({ variant }) => cardVariants[variant]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

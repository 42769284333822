import React from 'react';
import {
  FiAward,
  FiBarChart2,
  FiCheck,
  FiDatabase,
  FiHome,
  FiLayers,
  FiList,
  FiPackage,
  FiUsers,
} from 'react-icons/fi';

import { Card, Navigation, Wrapper } from './styles';
import { useHistory } from 'src/modules';

type Props = {
  name: string;
  icon: string;
  route: string;
};

// @TODO: Verificar os ícones inutilizáveis
const icons: MenuIconTypes = {
  rewards: <FiPackage />,
  'earn-points': <FiAward />,
  database: <FiDatabase />,
  user: <FiUsers />,
  levels: <FiBarChart2 />,
  home: <FiHome />,
  layers: <FiLayers />,
  check: <FiCheck />,
  list: <FiList />,
};

const MenuItem: React.FC<Props> = ({ name, icon, route }) => {
  const history = useHistory();

  const onMenuClick = () => history.push(`${route}`);

  return (
    <Wrapper>
      <Navigation>
        <Card onClick={onMenuClick}>
          <div>{icons[icon]}</div>
          {name}
        </Card>
      </Navigation>
    </Wrapper>
  );
};

export default MenuItem;

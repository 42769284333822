import { userInitialValues } from './user.consts';
import { PipelineSteps } from 'src/utils';

export const budgetsDetailsList: BudgetDetailsListTypes = {
  data: [],
  meta: {
    current_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page: 0,
    last_page_url: '',
    next_page_url: '',
    per_page: 0,
    previous_page_url: '',
    total: 0,
  },
};

export const budgetInitialValues: BudgetTypes = {
  id: 0,
  profession: '',
  service: 0,
  property_type: 0,
  measurements: ' ',
  when_to_start: '',
  description: '',
  city: '',
  state: '',
  zipcode: '',
  address: '',
  actual_step: PipelineSteps.NONE,
  comments: '',
  access_token: '',
  user_id: 0,
  created_at: '',
  updated_at: '',
  user: userInitialValues,
  pipelines: [],
};

export const applierInitialValues: AppliersTypes = {
  id: 0,
  full_name: '',
  phone: '',
  email: '',
  id_pdc: '',
  city: '',
  state: '',
  profession: '',
  description: '',
  status: '',
  created_at: '',
  updated_at: '',
  photo_url: '',
};

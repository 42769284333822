import { action } from 'mobx';

import { BudgetTrackingApi } from 'src/api';

export default class BudgetTrackingStore {
  @action
  applierClicked = async (
    budget_id: number,
    applier_id: number,
  ): Promise<void> => {
    await BudgetTrackingApi.applierClicked(budget_id, applier_id);
  };

  @action
  whatsappClicked = async (
    budget_id: number,
    applier_id: number,
  ): Promise<void> => {
    await BudgetTrackingApi.whatsappClicked(budget_id, applier_id);
  };

  @action
  applierContactFromDetails = async (
    budget_id: number,
    applier_id: number,
  ): Promise<void> => {
    await BudgetTrackingApi.applierContactFromDetails(budget_id, applier_id);
  };

  @action
  applierInvitationResponse = async (
    budget_id: number,
    applier_id: number,
    applier_response: string,
    refusal_reason: string,
    refusal_observations: string,
  ): Promise<void> => {
    const response = await BudgetTrackingApi.applierInvitationResponse(
      budget_id,
      applier_id,
      applier_response,
      refusal_reason,
      refusal_observations,
    );
    console.log('store', response);
  };

  @action
  userContactByApplier = async (
    budget_id: number,
    applier_id: number,
  ): Promise<void> => {
    await BudgetTrackingApi.userContactByApplier(budget_id, applier_id);
  };
}

import React, { HTMLInputTypeAttribute } from 'react';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';
import { Box } from './styles';

type InputIconsType = 'user' | 'password';
type ListIcons = { [key in InputIconsType]: JSX.Element };
type InputProps = {
  value: string;
  type?: HTMLInputTypeAttribute;
  handleChange(value: string): void;
  placeholder?: string;
  icon?: InputIconsType;
};

const inputIcons: ListIcons = {
  user: <AiOutlineUser />,
  password: <AiOutlineLock />,
};
const Input: React.FC<InputProps> = ({
  value,
  type = 'text',
  handleChange,
  placeholder,
  icon,
}) => {
  return (
    <Box>
      {icon ? inputIcons[icon] : null}
      <input
        type={type}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default Input;

import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { BudgetApi } from 'src/api';
import { budgetInitialValues, budgetsDetailsList } from 'src/constants';

export default class BudgetStore {
  @persist('object')
  @observable
  all: BudgetDetailsListTypes = budgetsDetailsList;

  @observable
  data: BudgetTypes = budgetInitialValues;

  constructor() {
    makeObservable(this);
  }

  @action
  fetchById = async (id: string): Promise<BudgetTypes> => {
    const budget = await BudgetApi.fetchBudgetById(id);

    this.data = budget;
    return budget;
  };

  @action
  fetch = async (
    accessToken: string,
    page = 1,
  ): Promise<BudgetDetailsListTypes> => {
    const budget = await BudgetApi.fetchBudget(accessToken, page);

    this.all = budget;
    return budget;
  };
}

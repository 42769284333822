import { create } from 'mobx-persist';
import ApplierStore from './applier.store';
import AuthStore from './auth.store';
import BudgetTrackingStore from './budget-tracking.store';
import BudgetStore from './budget.store';
import NorthStarStore from './north-star.store';
import WeeklyAnalysysStore from './weekly-analysys.store';

class RootStore {
  auth: AuthStore;

  budget: BudgetStore;

  applier: ApplierStore;

  budgetTracking: BudgetTrackingStore;

  weeklyAnalysys: WeeklyAnalysysStore;

  northStar: NorthStarStore;

  constructor() {
    this.auth = new AuthStore();
    this.budget = new BudgetStore();
    this.applier = new ApplierStore();
    this.budgetTracking = new BudgetTrackingStore();
    this.weeklyAnalysys = new WeeklyAnalysysStore();
    this.northStar = new NorthStarStore();
  }
}

const hydrate = create({
  storage: localStorage,
});
const store = new RootStore();

hydrate('auth', store.auth);

export { AuthStore };
export default store;

import React, { PropsWithChildren } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BottomRow, Box, ChildrenRow, HeadingRow } from './styles';
import { HighlightComponentProps } from './types';
import { formatToHighlightDate } from 'src/utils';

const HighlightComponent: React.FC<PropsWithChildren<HighlightComponentProps>> =
  ({ title, date, children }) => {
    return (
      <Box>
        <HeadingRow>
          <span>{title}</span>
          <AiOutlineInfoCircle />
        </HeadingRow>
        <ChildrenRow>{children}</ChildrenRow>
        <BottomRow>
          <span>{formatToHighlightDate(date)}</span>
        </BottomRow>
      </Box>
    );
  };

export default HighlightComponent;

import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { NorthStarApi } from 'src/api';
import { northStarInitialValue } from 'src/constants';

export default class NorthStarStore {
  @persist('object')
  @observable
  northStar: NorthStarType = northStarInitialValue;

  constructor() {
    makeObservable(this);
  }

  @action
  getNorthStar = async (
    start_date: Date,
    end_date: Date,
  ): Promise<NorthStarType> => {
    const data = await NorthStarApi.getNorthStar(start_date, end_date);
    this.northStar = data;
    return data;
  };
}

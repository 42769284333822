import request from './request';
import { ResponseError } from 'src/utils';
import { formatToAPIDate } from 'src/utils/helpers';

export const getNorthStar = async (
  start_date: Date,
  end_date: Date,
): Promise<NorthStarType> => {
  try {
    const { data } = await request.get('/goal/north-star', {
      params: {
        start_date: formatToAPIDate(start_date.toString()),
        end_date: formatToAPIDate(end_date.toString()),
        parametrization_id: '26f88e47-4a6d-41dc-9319-7a3509b1245c',
      },
    });
    if (!data.metricPercentage) data.metricPercentage = 0;
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

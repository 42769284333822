import moment from 'moment';

require('moment/locale/pt');

const ISO_DATE = 'YYYY-MM-DDTHH:mm:ss';
const SCREEN_DATE = 'DD/MM/YYYY';
const SHORT_DATE = 'DD/MM/YYYY';
const LONG_DATE = 'D [de] MMMM';
const HOUR_MINUTE = 'HH:mm';
const API_DATE = 'YYYY-MM-DD';
const TIME_LINE_DATE = 'DD [de] MMMM, YYYY';
const HIGHLIGHT_DATE = '[de] DD MMM, YYYY';

export const formatToAPIDate = (date: string): string =>
  moment.utc(date).format(API_DATE);

export const formatToScreenDate = (date: Date | string): string =>
  moment.utc(date, ISO_DATE).format(SCREEN_DATE);

export const formatScreenDateToISO = (date: Date): string =>
  moment.utc(date, SHORT_DATE).format(ISO_DATE);

export const formatShortDate = (date: Date): string =>
  moment.utc(date).format(SHORT_DATE);

export const formatLongDate = (date: Date): string =>
  moment.utc(date).format(LONG_DATE);

export const formatHourMinuteDate = (date: Date): string =>
  moment(date).format(HOUR_MINUTE);

export const formatToYear = (date: string): string =>
  moment(date).format('YYYY');

export const formatToTimeLineDate = (date: Date): string => {
  moment().locale('pt-br');

  return moment(date, ISO_DATE).format(TIME_LINE_DATE);
};

export const formatToHighlightDate = (date: Date): string => {
  moment().locale('pt-br');

  return moment(date, ISO_DATE).format(HIGHLIGHT_DATE);
};

export const formateDateByString = (date: Date, dateFormat: string): string => {
  moment().locale('pt-br');

  return moment(date, ISO_DATE).format(dateFormat);
};

export const convertMinsToHours = (mins: number): string => {
  const hours = Math.floor(mins / 60);
  const min = Math.round(mins % 60);
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMin = min < 10 ? `0${min}` : min;
  return `${formattedHours}h ${formattedMin} min`;
};

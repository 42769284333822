import { styled } from 'src/modules';

const characterPrimary = 'rgba(0, 0, 0, 0.85)';
type NumberProps = {
  color?: string;
};
export const Number = styled.strong<NumberProps>`
  display: block;
  color: ${characterPrimary};
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: ${({ color }) => color || characterPrimary};
`;

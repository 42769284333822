import request from './request';
import { ResponseError } from 'src/utils';

export const applierClicked = async (
  budget_id: number,
  applier_id: number,
): Promise<void> => {
  try {
    const { data } = await request.post(`/budget-tracking/applier-clicked`, {
      budget_id,
      applier_id,
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const whatsappClicked = async (
  budget_id: number,
  applier_id: number,
): Promise<void> => {
  try {
    const { data } = await request.post(`/budget-tracking/whatsapp-clicked`, {
      budget_id,
      applier_id,
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const applierContactFromDetails = async (
  budget_id: number,
  applier_id: number,
): Promise<void> => {
  try {
    const { data } = await request.post(
      `/budget-tracking/applier-contacted-from-details`,
      {
        budget_id,
        applier_id,
      },
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const applierInvitationResponse = async (
  budget_id: number,
  applier_id: number,
  applier_response: string,
  refusal_reason: string,
  refusal_observations: string,
): Promise<void> => {
  try {
    const { data } = await request.post(`/budget-tracking/applier-response`, {
      budget_id,
      applier_id,
      applier_response,
      refusal_reason,
      refusal_observations,
    });
    console.log('api', data);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const userContactByApplier = async (
  budget_id: number,
  applier_id: number,
): Promise<void> => {
  try {
    const { data } = await request.post(
      `/budget-tracking/user-contacted-by-applier`,
      {
        budget_id,
        applier_id,
      },
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { WeeklyAnalysysApi } from 'src/api';
import { weeklyanalysysInitialValues } from 'src/constants';

export default class WeeklyAnalysysStore {
  @persist('object')
  @observable
  analysys: WeeklyAnalysysType = weeklyanalysysInitialValues;

  constructor() {
    makeObservable(this);
  }

  @action
  getByPeriod = async (
    start_date: Date,
    end_date: Date,
  ): Promise<WeeklyAnalysysType> => {
    const data = await WeeklyAnalysysApi.getByPeriod(start_date, end_date);
    this.analysys = data;
    return data;
  };
}

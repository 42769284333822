import React from 'react';
import { AiFillExclamationCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { Box, Column, Row } from './styles';

type InfoBoxType = 'error' | 'warning';
type InfoBoxProps = {
  type: 'error' | 'warning';
  label1: string;
  label2?: string;
};
type InfoBoxIconType = {
  [key in InfoBoxType]: JSX.Element;
};
const icons: InfoBoxIconType = {
  error: <AiOutlineCloseCircle />,
  warning: <AiFillExclamationCircle />,
};
const InfoBox: React.FC<InfoBoxProps> = ({ label1, label2, type }) => {
  return (
    <Box type={type}>
      <Column>
        <div>{icons[type]}</div>
      </Column>
      <Column>
        <Row>
          <div>{label1}</div>
        </Row>
        {label2 && (
          <Row>
            <span>{label2}</span>
          </Row>
        )}
      </Column>
    </Box>
  );
};

export default InfoBox;

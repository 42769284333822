import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MenuItem } from './MenuItem';
import { NavBar } from './styles';

interface RouteMenu extends MenuType {
  collapsed?: boolean;
}
const MOCKED_MENUS: MenuType[] = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    submenus: [
      { id: 'analysys', name: 'Análises' },
      { id: 'marks', name: 'Marcos' },
      { id: 'opportunities', name: 'Oportunidades' },
      { id: 'next-steps', name: 'Próximos passos' },
    ],
  },
  { id: 'insights', name: 'Insights' },
  { id: 'account', name: 'Sua conta' },
];

const Sidebar: React.FC = () => {
  const history = useHistory();
  const [menus, setMenus] = useState<RouteMenu[]>([]);
  const fetchMenus = () => {
    setMenus(MOCKED_MENUS);
  };

  const onClickMenu = (id: string) => {
    setMenus((current) =>
      current.map((menu) => {
        if (menu.id === id) return { ...menu, collapsed: !menu.collapsed };
        return menu;
      }),
    );
  };

  const onNavigate = (id: string) => {
    history.push(`${id}`);
  };
  useEffect(() => {
    fetchMenus();
  }, []);

  const location = history.location.pathname.replace('/', '');
  const renderSubMenus = (subMenus: MenuType[]) => {
    return subMenus.map((subMenu) => (
      <MenuItem
        isSubMenu
        id={subMenu.id}
        name={subMenu.name}
        isSelected={subMenu.id === location}
        onCollapse={onClickMenu}
        onNavigate={onNavigate}
      />
    ));
  };
  return (
    <NavBar>
      {menus.map((menu) => (
        <>
          <MenuItem
            id={menu.id}
            name={menu.name}
            isCollapsed={menu.collapsed || false}
            onCollapse={onClickMenu}
          />
          {menu.collapsed ? renderSubMenus(menu.submenus || []) : null}
        </>
      ))}
    </NavBar>
  );
};

export default Sidebar;

import { styled } from 'src/modules';

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.neutral1};
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral5};
  padding: 0.5rem 1rem;
  input {
    display: flex;
    flex: 1;
    background: none;
    width: 100%;
    border: none;
    text-align: center;
  }
`;

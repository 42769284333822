export default {
  colors: {
    primary: '#1964AA',
    primaryDark: '#005099',
    secondary: '#845EF7',
    gray: '#8C91A5',
    grayLowerOpacity: '#abaebf',
    lightGray: '#F5F5F5',
    darkGray: '#495057',
    success: '#52C41A',
    error: '#dd0000',
    info: '#4324fa',
    label: '#616161',
    background: '#fff',
    yellow: '#ffd008',
    text: '#424242',
    textLight: '#9E9E9E',
    white: '#fff',
    line: '#EEEEEE',
    divider: 'rgba(0, 0, 0, 0.06)',
    blue: {
      primary1: '##E6F7FF',
      primary3: '#91D5FF',
      primary5: '#40a9ff',
      primary6: '#1890ff',
      contrast: '#fff',
    },
    character: {
      primary85: 'rgba(0, 0, 0, 0.85)',
      secondary45: 'rgba(0, 0, 0, 0.45)',
    },
    green: {
      polarGreen1: '#F6FFED',
      polarGreen3: '#B7EB8F',
      polarGreen8: '#237804',
    },
    red: {
      dustRed1: '#FFF1F0',
      dustRed2: '#FFCCC7',
      dustRed7: '#CF1322',
    },
    gold: {
      calendulaGold1: '#FFFBE6',
      calendulaGold3: '#FFE587',
    },
    neutral: {
      neutral1: '#FFFFFF',
      neutral4: '#F0F0F0',
      neutral5: '#D9D9D9',
    },
    brand: {
      secondary: {
        light: '#8B34A7',
        main: '#62009b',
        dark: '#62009b',
        contrast: '#fff',
      },
      tertiary: {
        light: '#2804FB',
        main: '#2804FB',
        dark: '#2804FB',
        contrast: '#fff',
      },
      accent: {
        light: '#ffd008',
        main: '#ffd008',
        dark: '#ffd008',
        contrast: '#fff',
      },
      success: {
        light: '#8CE99A',
        main: '#51CF66',
        dark: '#37B24D',
        contrast: '#fff',
      },
      danger: {
        light: '#dd0000',
        main: '#dd0000',
        dark: '#dd0000',
        contrast: '#fff',
      },
      warning: {
        light: '#FFF6B3',
        main: '#FFF53D',
        dark: '#C6BB38',
        contrast: '#000',
      },
      info: {
        light: '#91A7FF',
        main: '#5C7CFA',
        dark: '#4263EB',
        contrast: '#fff',
      },
      text: {
        light: '#adb5bd',
        main: '#757575',
        dark: '#212529',
        contrast: '#fff',
      },
      background: {
        z0: '#ffffff',
        z1: '#f1f3f5',
        z2: '#f0f0f0',
        z3: '#dddddd',
        z4: '#848C94',
      },
    },
  },
  fontSizes: {
    default: '1.4rem',
    large: '1.6rem',
    small: '1.2rem',
    tiny: '1rem',
  },
  spacing: {
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
  },
  transition: {
    default: '180ms ease-in-out',
  },
  radii: {
    default: '0.8rem',
    small: '0.4rem',
    smallTop: '0.4rem 0.4rem 0 0',
    smallBottom: '0 0 0.4rem 0.4rem',
    mediumTop: '0.8rem 0.8rem 0 0',
    largeTop: '1rem 1rem 0 0',
    tiny: '0.2rem',
  },
  shadows: {
    default: '0 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    flat: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
};

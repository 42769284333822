import React, { useEffect, useState } from 'react';
import {
  Button,
  ComparativePercentage,
  HighlightComponent,
  Loader,
  Numeral,
} from '..';
import { Box, HeadingRow, HighlightsList } from './styles';
import { WeeklyAnalysisProps } from './types';
import DatePicker, {
  DatePickerKeys,
  DatePickerValue,
} from 'src/components/DatePicker';
import { weeklyanalysysInitialValues } from 'src/constants';
import { useStores } from 'src/hooks';
import { formateDateByString } from 'src/utils';

const WeeklyAnalysys: React.FC<WeeklyAnalysisProps> = ({ data }) => {
  const { weeklyAnalysys } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateIntervalKey, setDateIntervalKey] =
    useState<DatePickerKeys>('days7');
  const [weeklyData, setWeeklyData] = useState<WeeklyAnalysysType>(
    weeklyanalysysInitialValues,
  );
  const { title, date, lastUpdated } = data;

  const end_date = new Date();
  const start_date = new Date();
  start_date.setDate(end_date.getDate() - 7);
  const fetchData = async (dateInterval: DateIntervalType) => {
    setIsLoading(true);
    console.log('FETCHING DATA', start_date, end_date);
    try {
      const data = await weeklyAnalysys.getByPeriod(
        dateInterval?.start_date,
        dateInterval.end_date,
      );
      setWeeklyData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onChangeDate = (changeValue: DatePickerValue) => {
    setDateIntervalKey(changeValue.key);

    fetchData(changeValue.value);
  };
  useEffect(() => {
    fetchData({ start_date, end_date });
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <HeadingRow>
            <h3>Análise geral semanal</h3>
            <div>
              <DatePicker keyValue={dateIntervalKey} onChange={onChangeDate} />
            </div>
          </HeadingRow>
          <h2>{title}</h2>
          <strong>
            Insights Atualizados{' '}
            <span>{formateDateByString(new Date(), 'DD, MMM, YYYY')}</span>
          </strong>
          <HighlightsList>
            <HighlightComponent title="Insight" date={new Date()}>
              <p>
                A fonte de tráfego de pesquisa do Facebook é o canal com maior
                número de novos usuários
              </p>
            </HighlightComponent>
            <HighlightComponent title="Investimento Total" date={new Date()}>
              <Numeral type="currency" value={weeklyData.totalInvestment} />
            </HighlightComponent>
            <HighlightComponent title="Custo por conversão" date={new Date()}>
              <Numeral type="currency" value={weeklyData.unitCost.value} />
              <span>
                7 dias{' '}
                <ComparativePercentage
                  value={weeklyData.unitCost.percentageValue7DaysAgo}
                />
              </span>
              <span>
                30 dias{' '}
                <ComparativePercentage
                  value={weeklyData.unitCost.percentageValue30DaysAgo}
                />
              </span>
            </HighlightComponent>
            <HighlightComponent title="Total de conversão" date={new Date()}>
              <Numeral type="number" value={weeklyData.totalUsers.value} />
              <span>
                7 dias{' '}
                <ComparativePercentage
                  value={weeklyData.totalUsers.percentageValue7DaysAgo}
                />
              </span>
              <span>
                30 dias{' '}
                <ComparativePercentage
                  value={weeklyData.totalUsers.percentageValue30DaysAgo}
                />
              </span>
            </HighlightComponent>
          </HighlightsList>
          {/* <Button variant="primary" style={{ marginTop: 24 }}>
        {' '}
        Ver detalhes
      </Button> */}
        </>
      )}
    </Box>
  );
};

export default WeeklyAnalysys;

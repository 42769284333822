import { styled } from 'src/modules';

export const Container = styled.header`
  width: 100%;
  height: 48px;
  background-color: #002766;
  padding-inline: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const weeklyanalysysInitialValues: WeeklyAnalysysType = {
  totalInvestment: 0,
  unitCost: {
    value: 0,
    percentageValue7DaysAgo: 0,
    percentageValue30DaysAgo: 0,
  },
  totalUsers: {
    value: 0,
    percentageValue7DaysAgo: 0,
    percentageValue30DaysAgo: 0,
  },
};

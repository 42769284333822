import request from './request';
import { ResponseError } from 'src/utils';

export const getById = async (id: string): Promise<AppliersTypes> => {
  try {
    const { data } = await request.get(`/appliers/${id}`);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const getByPdcId = async (id: string): Promise<AppliersTypes> => {
  try {
    const { data } = await request.get(`/appliers/pdc/${id}`);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const fetchInvitation = async (
  accessToken: string,
  id: string,
): Promise<ApplierInvitation> => {
  try {
    const { data } = await request.get(
      `/appliers/invitation/${accessToken}/${id}`,
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

import { styled } from 'src/modules';

const primary = '#002766';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
`;

export const Left = styled.div`
  height: 100%;
  width: 33%;
  background-color: ${primary};
`;

export const Right = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

export const Image = styled.img``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
`;

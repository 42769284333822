import { styled } from 'src/modules';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const Main = styled.main`
  padding: 24px;
`;

import React from 'react';
import { observer } from 'mobx-react-lite';

import { Content, Main } from './styles';
import { Sidebar } from 'src/components';
import Header from 'src/components/Header';

type Props = {
  hasGradient?: boolean;
  hasLogo?: boolean;
};

const DefaultLayout: React.FC<Props> = ({
  hasGradient = false,
  hasLogo = false,
  children,
}) => {
  return (
    <>
      <Header />

      <Content>
        <Sidebar />
        <Main>{children}</Main>
      </Content>
    </>
  );
};

export default observer(DefaultLayout);

import { styled } from 'src/modules';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii.largeTop};
  box-shadow: 0px 0px 30px rgba(51, 48, 62, 0.2);
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 25px 0 35px 0;
  width: 1200px;

  p {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    justify-content: space-around;

    p {
      display: block;
    }
  }

  @media only screen and (min-width: 1360px) {
    justify-content: space-between;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    width: 40%;
  }
`;

export const ButtonAccept = styled.button`
  width: 49.5%;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii.default};

  :hover {
    color: ${({ theme }) => theme.colors.white};
    border: none;
    transition: 0.15s;
  }
`;

export const ButtonDecline = styled.button`
  width: 49.5%;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.3rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: ${({ theme }) => theme.colors.white};

  :hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.error};
    border: 1px solid ${({ theme }) => theme.colors.error};
    transition: 0.15s;
  }
`;

export const ButtonDeclineDisabled = styled.button`
  width: 90%;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1.3rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.radii.default};

  :hover {
    cursor: default;
  }

  @media only screen and (min-width: 768px) {
    width: 413px;
  }
`;

export const ButtonWhatsapp = styled.a`
  width: 90%;
  text-align: center;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.3rem;
  border-radius: ${({ theme }) => theme.radii.default};

  @media only screen and (min-width: 768px) {
    width: 413px;
  }
`;
